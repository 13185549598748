<template>
  <no-ssr>
    <overlay-scrollbars :options="{ scrollbars: { visibility: 'h' } }" ref="customerCareScrollbar" class="wrapp-link-list">
      <ul class="link-list" id="link-list-collapse">
        <b-nav-item v-for="link in links" :key="link.key" class="link-list__item" :to="link.to" link-classes="link-list__item__cta bcm-link">
          <span v-t="link.key"></span>
        </b-nav-item>
      </ul>
    </overlay-scrollbars>
  </no-ssr>
</template>

<script>
import $ from 'jquery'
import { pathTranslate } from '@/helpers/routeHelpers'

export default {
  data: () => ({
    activeSlug: ''
  }),
  computed: {
    links () {
      return [
        {
          key: 'route.contact-us',
          to: { name: 'ContactUs', params: { slug: pathTranslate({ path: 'contacts' }) } }
        },
        {
          key: 'route.faq',
          to: { name: 'factoryPage', params: { slug: pathTranslate({ path: 'faq' }) } }
        },
        {
          key: 'route.follow-your-order',
          to: { name: 'FollowYourOrder', params: { slug: pathTranslate({ path: 'follow-your-order' }) } }
        },
        {
          key: 'route.shippings',
          to: { name: 'factoryPage', params: { slug: pathTranslate({ path: 'time-of-delivery' }) } }
        },
        {
          key: 'route.returns-and-refunds',
          to: { name: 'factoryPage', params: { slug: pathTranslate({ path: 'returns-and-refunds' }) } }
        },
        {
          key: 'route.payment-methods',
          to: { name: 'factoryPage', params: { slug: pathTranslate({ path: 'payment' }) } }
        },
        {
          key: 'route.fashion-expert',
          to: { name: 'FashionExpert', params: { slug: pathTranslate({ path: 'personal-shopper' }) } }
        },
        {
          key: 'route.book-an-appointment',
          to: { name: 'BookAnAppointment', params: { slug: pathTranslate({ path: 'book-an-appointment' }) } }
        }
      ]
    }
  },
  methods: {
    clickButton () {
      $('.btn-show-menu').click()
    }
  },
  mounted () {
    let slug = this.$route.params.slug
    const _this = this
    if (slug) {
      let activeSlug = this.links.find((el) => {
        return el.to.params.slug === slug
      })
      this.activeSlug = activeSlug ? activeSlug.key : ''
    } else {
      this.activeSlug = 'contacts'
    }
    if (process.client) {
      this.$nextTick(() => {
        let element = document.querySelector('.shoulder-left').querySelector('.router-link-exact-active')
        const osInstance = _this.$refs.customerCareScrollbar.osInstance()
        window.myistance = osInstance
        osInstance.scroll({ el: element, scroll: 'ifneeded', block: ['begin', 'end'], margin: 100 })
        setTimeout(function () {
          osInstance.scroll({ el: element, scroll: 'ifneeded', block: ['begin', 'end'], margin: 100 })
        }, 200)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
ul{
  padding: 0;
  margin: 0;
  list-style: none;
  border-bottom: 1px #ececec solid;

  li {
    margin-bottom: 15px;
    padding: 0;
    margin: 0 5px !important;
    .bcm-link-primary {
      &::after {
        width: 0;
      }

      &:hover {
        &::after {
          width: 100%;
        }
      }

      &.active,
      &.router-link-active {
        &::after {
          width: 100%;
        }
      }
    }
  }
}
</style>
